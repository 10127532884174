/* Home Hero ------------------------- */
/* -------------------------------------------------- */
.home-hero {
    background: url(../img/group_img/home-snow-bucket-in-use-s.webp) no-repeat center top;
    background-size: cover;
}

.home-hero.lazy {
     background: none;
     background-size: cover;
}

.home-hero-content-block {
    width: 90%;
    max-width: 800px;
    padding: 32px;
    margin-left: 5%;
    background: rgba(255,255,255,0.5);
}

.home-hero h1 {
    font-size: 2em;
}

/* Home half sections ------------------------- */
/* -------------------------------------------------- */
.home-who-we-are-bg {
    background: url(../img/group_img/group-photo.webp) no-repeat 50% 40%;
    background-size: cover;
}

.home-who-we-are-bg.lazy {
    background: none;
    background-size: cover;
 }

.home-attachments-bg.lazy {
    background: none;
    background-size: cover;
}

.home-attachments-bg {
    background: url(../img/group_img/home-welder-working-on-skeleton-grapple-s.webp) no-repeat center 35%;
    background-size: cover;
}


/* Why Choose Us ------------------------- */
/* -------------------------------------------------- */
.home-choice-blocks {
    margin-top: 40px;
}

.home-why-block {
    padding: 8px 5% 24px;
    margin-bottom: 40px;
    box-shadow: 0 0 12px rgba(0,0,0,0.2);
    text-align: center;
}

    .home-why-block img {
        position: relative;
        display: block;
        width: 100%;
        max-width: 200px;
        height: auto;
        margin: 0 auto;
    }

    .home-why-block .highlight-container {
        margin: 16px 0;
    }

    .home-why-block .highlight {
        font-weight: 700;
        text-transform: uppercase;
    }


/* Snazzy Map ------------------------- */
/* -------------------------------------------------- */
.home-map-container,
.home-map-container iframe {
    width: 100%;
    height: 100%;
    border: none;
}



@media screen and (min-width: 400px) {

    .home-hero h1 {
        font-size: 2.5em;
    }
}


@media screen and (min-width: 520px) {

    .home-hero h1 {
        font-size: 3em;
    }
}



@media screen and (min-width: 640px) {
    .home-hero h1 {
        font-size: 3.5em;
    }

    .home-hero {
        background: url(../img/group_img/home-snow-bucket-in-use-l.webp) no-repeat center top;
        background-size: cover;
    }

    .home-attachments-bg {
        background: url(../img/group_img/home-welder-working-on-skeleton-grapple-s.webp) no-repeat center 35%;
        background-size: cover;
    }
}


@media screen and (min-width: 1000px) {

    .home-hero {
        background: url(../img/group_img/home-snow-bucket-in-use-full.webp) no-repeat center top;
        background-size: cover;
    }

    /* Why Choose Us ------------------------- */
    /* -------------------------------------------------- */
    .home-choice-blocks {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
    }

    .home-why-block {
        width: 31%;
        padding-left: 16px;
        padding-right: 16px;
    }
}
