/* Contact ------------------------- */
/* -------------------------------------------------- */
.contact-banner {
    background: url(../img/group_img/contact-bg-s.webp) no-repeat center top;
    background-size: cover;
}


/* Contact Options ------------------------- */
/* -------------------------------------------------- */
.contact-option {
    width: 240px;
    margin: 0 auto 32px;
    line-height: 1.4em;
    text-align: left;
}
.contact-option svg {
    fill: var(--white);
    display: inline-block;
    vertical-align: middle;
    margin: 0 24px 0 0;
}
.contact-option-text {
    display: inline-block;
    vertical-align: middle;
}
.contact-option-text a {
    color: var(--white);
}
.contact-option-text a:hover {
    color: var(--primary);
}


/* Snazzy Map ------------------------- */
/* -------------------------------------------------- */
.snazzy-map-banner {
    height: 75vw;
}
.snazzy-map-banner iframe {
    width: 100%;
    height: 100%;
    border: none;
}



@media screen and (min-width: 560px) {

    .contact-banner {
        background: url(../img/group_img/contact-bg-m.webp) no-repeat center top;
        background-size: cover;
    }

    .contact-options {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
    }
    .contact-option {
        width: 30%;
        text-align: center;
    }
    .contact-option svg {
        display: block;
        margin: 0 auto 16px;
    }
    .contact-option-text {
        display: block;
    }

}


@media screen and (min-width: 768px) {

    .contact-banner {
        background: url(../img/group_img/contact-bg-full.webp) no-repeat center top;
        background-size: cover;
    }

    .snazzy-map-banner {
        height: 50vw;
        max-height: 700px;
    }
}


@media screen and (min-width: 1000px) {

    .contact-columns {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
    }
    .contact-column.contact-options {
        margin-right: 100px;
    }
    .contact-column.contact-form {
        flex-grow: 1;
    }

    .contact-options {
        display: block;
    }
    .contact-option {
        width: auto;
        text-align: left;
    }
    .contact-option svg {
        display: inline-block;
        vertical-align: middle;
        margin: 0 24px 0 0;
    }
    .contact-option-text {
        display: inline-block;
        vertical-align: middle;
    }
}