/* Main Product section ------------------------- */
/* -------------------------------------------------- */
.product-featured-image {
    z-index: 2;
    margin-bottom: 20px;
}

    .product-featured-image .image img {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
    }

.product-cart-form .price {
    position: relative;
    display: inline-block;
    padding: 14px 18px 18px;
    margin: 18px 0;
    background: var(--grey);
    line-height: 1em;
}

.info-message-product {
    display: block;
    width: 100%;
}

.product-column + .product-column {
    margin-top: 32px;
}


/* Product hotspots ------------------------- */
/* -------------------------------------------------- */
.hotspot-container {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hotspot {
    pointer-events: initial;
    cursor: pointer;
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: var(--secondary);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

    .hotspot:hover {
        transform: scale(1.2);
        background: var(--primary);
    }

    .hotspot span {
        position: absolute;
        left: 50%;
        top: 50%;
        display: block;
        margin: -2px 0 0 -8px;
        width: 16px;
        height: 4px;
        background: var(--white);
    }

        .hotspot span:first-child {
            transform: rotate(90deg);
        }

.hotspot-popup {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    border: 2px solid var(--grey);
    background: var(--lightgrey);
    transform: scale(0.65);
    font-size: var(--font-size-xl);
    pointer-events: initial;
}

    .hotspot-popup .close-button {
        z-index: 1;
        top: -20px;
        right: -20px;
        margin: 0;
    }

    .hotspot-popup .close-button {
        pointer-events: initial;
    }

.product-featured-image .image .hotspot-container img {
    max-width: initial;
}

.hotspot-description {
    padding: 16px 24px 20px;
}

    .hotspot-description h4:last-child {
        margin-bottom: 0;
    }

    .hotspot-description p:first-child {
        margin-top: 0;
    }



/* Product details - specs table ------------------------- */
/* -------------------------------------------------- */
.spec-table {
    display: block;
    max-width: 1200px;
    margin: 0 auto;
    border: 2px solid var(--charcoal);
}

    .spec-table thead,
    .spec-table tbody {
        display: block;
    }

    .spec-table .spec-table-row {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .spec-table .spec-table-col {
        flex-grow: 1;
        width: 40px;
    }

    .spec-table .spec-table-body {
        border-top: 2px solid var(--grey);
        border-right: 2px solid var(--grey);
    }

        .spec-table .spec-table-body:last-child {
            border-right: none;
        }

    .spec-table .spec-table-header {
        background: var(--charcoal);
        border: none;
        font-weight: 700;
        color: var(--white);
        text-transform: uppercase;
    }



/* Product details - specs - features - buttons ------------------------- */
/* -------------------------------------------------- */
.product-details + .product-details {
    margin-top: 50px;
}

.product-listings.similar-listings {
    width: 100%;
}



/* Product Testimonies ------------------------- */
/* -------------------------------------------------- */
.testimony-container {
    width: 90%;
    margin: 0 auto;
}

.testimony {
    margin-bottom: 32px;
    color: var(--white) !important;
}

.testimony-by {
    font-family: var(--title-font);
    font-size: var(--font-size-l);
    font-weight: 700;
    text-transform: uppercase;
}

    .testimony-by span {
        font-weight: 400;
    }

#product-testimonials .slick-prev {
    z-index: 1;
    left: -16px;
}

#product-testimonials .slick-next {
    z-index: 1;
    right: -16px;
}

#product-testimonials .slick-dots {
    bottom: initial;
}

    #product-testimonials .slick-dots li button:before {
        font-size: 9px;
        color: var(--white);
        opacity: 0.5;
    }

    #product-testimonials .slick-dots li.slick-active button:before {
        color: var(--white);
        opacity: 1;
    }



/* Product Image Gallery ------------------------- */
/* -------------------------------------------------- */
.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 425px !important;
}

.product-columns .image-gallery-thumbnail.active,
.product-columns .image-gallery-thumbnail:focus {
    border-color: var(--primary) !important;
}

.product-columns .image-gallery-thumbnail:hover {
    border-color: var(--secondary);
}


/* Product Video Gallery ------------------------- */
/* -------------------------------------------------- */
.video {
    margin-bottom: 24px;
    margin-top: 15px;
}

.product-column.gallery-column + .product-column.video-column {
    margin-top: 40px;
}



@media (min-width: 768px) {
    .product-columns {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
    }

    .product-column {
        width: 46%;
        max-width: 1200px;
    }

        .product-column.product-description {
            order: 1;
        }

        .product-column + .product-column {
            margin-top: 0;
        }

        .product-column.product-image-form {
            order: 2;
        }

    /* Product details - features list ------------------------- */
    /* -------------------------------------------------- */
    .product-features.long-text ul {
        -moz-column-gap: 2;
        -webkit-column-count: 2;
        column-count: 2;
        column-gap: 40px;
    }

    .product-features.long-text li {
        display: inline-block;
        width: calc(100% - 40px);
    }

    /* Media ------------------------- */
    /* -------------------------------------------------- */
    .product-column.gallery-column,
    .product-column.video-column {
        width: 100%;
        margin: 0 auto;
    }

    .video-column {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
    }

        .video-column .video {
            width: 48%;
        }
}