/* Orders section ------------------------- */
/* -------------------------------------------------- */
.heading-with-button {
    text-align: center;
}
input#searchOrder {
    padding: 4px 8px;
    margin: 10px 0 0;
    border: 1px solid var(--charcoal);
    outline: none;
}
input#searchOrder:focus {
    border-color: var(--secondary);
}

#myTable {
    position: relative;
    display: block;
    width: 100%;
}
#myTable thead {
    display: none;
}
#myTable tbody {
    display: block;
}
#myTable tr {
    display: inline-block;
    width: 48%;
    margin-bottom: 16px;
    border: 1px solid var(--charcoal);
}
#myTable tr:nth-child(odd) {
    margin-right: 4%;
}
#myTable td {
    display: block;
    width: 100%;
}
#myTable th {
    border: none;
}
.order-table-view.viewOrder {
    background: var(--secondary);
    color: var(--white);
    cursor: pointer;
}
.order-table-view.viewOrder:hover {
    background: var(--primary);
}
#myTable tr.hide-order {
    display: none;
}
.active-order {
    display: flex;
}



@media screen and (min-width: 330px) {
    .heading-with-button {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
}


@media screen and (min-width: 540px) {
   
    #myTable thead {
        display: block;
        background: var(--charcoal);
        color: var(--white);
    }
    #myTable tr {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        margin-bottom: 0;
        margin-right: 0 !important;
        border: none;
    }
    #myTable td {
        display: initial;
        width: initial;
    }
    #myTable td.order-table-order,
    #myTable th.order-table-order{
        width: 80px;
    }
    #myTable td.order-table-date,
    #myTable th.order-table-date{
        width: 160px;
        flex-grow: 1;
    }
    #myTable td.order-table-total,
    #myTable th.order-table-total{
        width: 120px;
        flex-grow: 1;
    }
    #myTable td.order-table-view,
    #myTable th.order-table-view{
        width: 160px;
        text-align: center;
    }
}



@media screen and (min-width: 640px) {
    .address-blocks {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
    }
    .address-block {
        width: 48%;
    }
}