/* Form Layout ------------------------- */
/* -------------------------------------------------- */
.form-section-break {
    padding: 16px 0;
}


/* Forms Labels ------------------------- */
/* -------------------------------------------------- */
.form-field label {
    display: block;
    width: calc(100% - 24px);
    margin: 0 0 6px;
    line-height: 1.15em;
}

/* Forms Fields ------------------------- */
/* -------------------------------------------------- */
.form-field {
    margin: 0 0 16px;
}
.form-field input,
.form-field select,
.form-field textarea {
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
    padding: 12px 16px;
    background: var(--white);
    border: 1px solid var(--charcoal);
    outline: none;
    color: var(--black);
    line-height: 1.25em;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}
.form-field input:focus,
.form-field select:focus,
.form-field textarea:focus {
    border-color: var(--secondary);
    background: #FAF7DA;
    outline: none;
}

/* Modify fields on specific background colors ---------------------------------------------- */
bg.black .form-field input,
bg.black .form-field select,
bg.black .form-field textarea {
    border-color: var(--black);
}

/* Field Specific - Textarea ---------------------------------------------- */
.form-field textarea {
    height: 180px;
}

/* Field Specific - Select dropdowns - arrow ---------------------------------------------- */
.form-field.select-field:after {
    content: '';
    position: absolute;
    right: 16px;
    top: 18px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid var(--charcoal);
    pointer-events: none;
}
.form-field.select-field.has-label:after {
    top: 40px;
}


/* Checkboxes - Radio Buttons ------------------------- */
/* -------------------------------------------------- */
.form-field.form-checkboxes label,
.form-field.form-radio-options label {
    display: inline-block;
    width: initial;
    margin: 0;
}

.form-field input[type=checkbox],
.form-field input[type=radio] {
    top: -2px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    padding: 0;
    margin-right: 8px;
}
.form-field input[type=radio] {
    border-radius: 12px;
}
.form-field input[type=checkbox]:checked,
.form-field input[type=radio]:checked {
    border-color: var(--secondary);
}

.form-field input[type=checkbox]:before,
.form-field input[type=checkbox]:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: 4px;
    background: var(--secondary);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.form-field input[type=checkbox]:before {
    margin: -2px 0 0 -7px;
    width: 14px;
    transform: translate(22px, 0px) rotate(-45deg);
}
.form-field input[type=checkbox]:after {
    margin: -2px 0 0 -4px;
    width: 8px;
    transform: translate(-24px, 2px) rotate(45deg);
}
.form-field input[type=checkbox]:checked:before {
    margin: -2px 0 0 -7px;
    width: 14px;
    transform: translate(2px, 0px) rotate(-45deg);
}
.form-field input[type=checkbox]:checked:after {
    margin: -2px 0 0 -4px;
    width: 8px;
    transform: translate(-4px, 2px) rotate(45deg);
}


/* Field Icons - password visibility ------------------------- */
/* -------------------------------------------------- */
.password-field i {
    position: absolute;
    right: 12px;
    top: 13px;
    margin: 0 !important;
    line-height: 1em;
}
.password-field label ~ i {
    top: 0;
    right: 0;
}


/* Submit button ------------------------- */
/* -------------------------------------------------- */
.form-field input[type=submit] {
    max-width: 280px;
    margin: 0 auto;
    padding: 13px 5px;
    border: none !important;
    background: var(--secondary);
    font-weight: 700;
    color: var(--white);
    text-transform: uppercase;
}
.form-field.form-submit-right input[type=submit] {
    margin: 0 0 0 auto;
}
.form-field input[type=submit]:hover {
    background: var(--primary);
}
.form-alt-options {
    margin-top: 16px;
    font-size: var(--font-size-s);
    text-align: center;
}
.form-alt-options .link {
    color: var(--secondary);
    text-decoration: underline;
    cursor: pointer;
}
.form-alt-options .link:hover {
    color: var(--primary);
}
.spinner-grow {
    display: block;
    margin: 0 auto;
}
.form-submit-note {
    display: none;
    width: 100%;
    padding: 8px 12px;
    border: 1px solid var(--success);
    color: var(--success);
    font-size: var(--font-size-s);
    line-height: 1.3em;
    text-align: center;
}
.form-submit-note a {
    text-decoration: underline !important;
    color: var(--success);
}
.form-disclaimer {
    padding: 16px 24px;
    background: var(--grey);
    font-size: var(--font-size-s);
    text-align: center;
}


/* Form Agreements ------------------------- */
/* -------------------------------------------------- */
.form-agreement {
    overflow: auto;
    padding: 24px;
    background: var(--lightgrey);
    font-size: var(--font-size-xs);
    line-height: 1.4em;
}
.form-field.form-agreement input[type=checkbox] {
    float: left;
    margin-right: 16px;
}
.form-agreement-text {
    display: block;
    overflow: hidden;
}


/* Form Errors ------------------------- */
/* -------------------------------------------------- */
.error-message {
    margin-top: 48px;
    padding: 12px 24px;
    border: 1px solid var(--primary);
    font-weight: 700;
    text-align: center;
    color: var(--primary);
}
.success-message {
    margin-top: 48px;
    padding: 12px 24px;
    border: 1px solid var(--success);
    font-weight: 700;
    text-align: center;
    color: var(--success);
}




@media screen and (min-width: 500px) {
    .form-field-container.form-half-fields {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
    }
    .form-field-container.form-half-fields .form-field {
        width: 48.5%;
    }
    .form-field-container.form-half-fields .form-field.small-field {
        width: 33.5%;
    }
    .form-field-container.form-half-fields .form-field.large-field {
        width: 63.5%;
    }
    .form-field-container.form-half-fields .form-field.xs-field {
        width: 23.5%;
    }
    .form-field-container.form-half-fields .form-field.xl-field {
        width: 73.5%;
    }

}

@media screen and (min-width: 560px) {
    .form-field.select-field:after {
        top: 20px;
    }
    .form-field.select-field.has-label:after {
        top: 44px;
    }
}

@media screen and (min-width: 768px) {
    .form-field.select-field.has-label:after {
        top: 48px;
    }
}