/* About ------------------------- */
/* -------------------------------------------------- */
.about-hero {
    background: url(../img/group_img/about-hero-welder-working-on-attachment-s.webp) no-repeat 30% 70%;
    background-size: cover;
}

/* Tertiary content section ------------------------- */
/* -------------------------------------------------- */
.about-tertiary-banner-bg {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: 80vw;
    background: url(../img/group_img/about-tertiary-bg-2-s.webp) no-repeat 50% 0%;
    background-size: cover;
}
.about-tertiary-banner-filter {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    background: transparent;
    background: -moz-linear-gradient(0deg, rgba(22,22,22,1) 60%, rgba(22,22,22,0) 100%, rgba(22,22,22,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(22,22,22,1) 60%, rgba(22,22,22,0) 100%, rgba(22,22,22,0) 100%);
    background: linear-gradient(0deg, rgba(22,22,22,1) 60%, rgba(22,22,22,0) 100%, rgba(22,22,22,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#222222",endColorstr="#222222",GradientType=1);
}
.about-tertiary-content .wrapper {
    position: relative;
    z-index: 3;
}
.about-tertiary-right {
    position: relative;
    display: block;
    width: 100%;
}


/* half column section ------------------------- */
/* -------------------------------------------------- */
.about-columns-bg {
    display: block;
    background: url(../img/group_img/group-photo-mobile.webp) no-repeat 50% 40%;
    background-size: cover;
}


/* Famous for banner ------------------------- */
/* -------------------------------------------------- */
.famous-for-banner .wrapper {
    z-index: 2;
}
.split-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.half-bg {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 50%;
    height: 100%;
}
.left-bg {
    left: 0;
    background: url(../img/group_img/about-debris-grapple-s.webp) no-repeat center center;
    background-size: cover;
}
.right-bg {
    right: 0;
    background: url(../img/group_img/about-hydraulic-angle-winged-snow-blade-s.webp) no-repeat center center;
    background-size: cover;
}



@media screen and (min-width: 640px) {

    .about-hero {
        background: url(../img/group_img/about-hero-welder-working-on-attachment-m.webp) no-repeat 30% 70%;
        background-size: cover;
    }

    /* half column section ------------------------- */
    /* -------------------------------------------------- */
    .about-columns-bg {
        background: url(../img/group_img/group-photo.webp) no-repeat 50% 40%;
        background-size: cover;
    }

    /* Famous for banner ------------------------- */
    /* -------------------------------------------------- */
    .left-bg {
        background: url(../img/group_img/about-debris-grapple-full.webp) no-repeat center center;
        background-size: cover;
    }
    .right-bg {
        background: url(../img/group_img/about-hydraulic-angle-winged-snow-blade-full.webp) no-repeat center center;
        background-size: cover;
    }

    /* Tertiary content section ------------------------- */
    /* -------------------------------------------------- */
    .about-tertiary-banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: url(../img/group_img/about-tertiary-bg-2-large.webp) no-repeat 30% 30%;
        background-size: cover;
    }
    .about-tertiary-banner-filter {
        background: -moz-linear-gradient(270deg, rgba(22,22,22,1) 20%, rgba(22,22,22,0) 100%, rgba(22,22,22,0) 100%);
        background: -webkit-linear-gradient(270deg, rgba(22,22,22,1) 20%, rgba(22,22,22,0) 100%, rgba(22,22,22,0) 100%);
        background: linear-gradient(270deg, rgba(22,22,22,1) 20%, rgba(22,22,22,0) 100%, rgba(22,22,22,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#222222",endColorstr="#222222",GradientType=1);
    }
    .about-tertiary-right {
        padding-top: 0;
        width: 50%;
        margin-left: 50%;
    }

}


@media screen and (min-width: 900px) {

    .about-hero {
        background: url(../img/group_img/about-hero-welder-working-on-attachment-l.webp) no-repeat 30% 40%;
        background-size: cover;
    }

}


@media screen and (min-width: 1200px) {

    .about-hero {
        background: url(../img/group_img/about-hero-welder-working-on-attachment-full.webp) no-repeat 30% 70%;
        background-size: cover;
    }
}