/* Thank you page ------------------------- */
/* -------------------------------------------------- */
.thank-you {
    background: url(../img/group_img/thank-you-background.webp) no-repeat center center;
    background-size: cover;
}


/* Thank you social media ------------------------- */
/* -------------------------------------------------- */
.thank-you-social {
    display: block;
    margin: 8px 0 0;
}
.thank-you-social ul {
    margin: 32px 0 0;
    padding: 0;
    text-align: center;
}
.thank-you-social ul li {
    display: inline-block;
}
.thank-you-social a {
    display: inline-block;
    margin: 0 8px;
}
.thank-you-social a svg {
    fill: var(--black);
    width: 40px;
    height: 40px;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.thank-you-social a:hover svg {
    fill: var(--secondary);
}



@media screen and (min-width: 1220px) {

}
