/* Cart ------------------------- */
/* -------------------------------------------------- */
.close-button.remove-cart-item {
    top: -14px;
    right: -14px;
    z-index: 1;
    width: 28px;
    height: 28px;
    margin: 0;
}
.cart-item {
    margin-bottom: 32px;
    border: 2px solid var(--charcoal);
    font-size: var(--font-size-s);
}
.cart-item-info {
    padding: 8px 12px;
}
.cart-item-selection {
    border-bottom: 1px dotted var(--charcoal);
}
.cart-item-selection:last-child {
    border: none;
}
.cart-item-row span {
    display: inline-block;
    width: 50%;
    padding: 4px;
}
.cart-item-row span:nth-child(even) {
    text-align: right;
}

.cart-item-column.cart-item-totals {
    padding-top: 8px;
    background: var(--grey);
}
.cart-item-row.cart-item-total span {
    padding-left: 16px;
    padding-right: 16px;
}
.cart-item-row.cart-item-total span.form-field {
    margin: 0;
}
.cart-item-row.cart-item-total span.form-field input {
    padding: 2px 8px;
    background: var(--grey);
    border-color: var(--darkgrey);
    text-align: right;
}
.cart-item-row.cart-item-total:last-child {
    margin-top: 8px;
    background: var(--charcoal);
    color: var(--white);
    font-size: var(--font-size-l);
    font-weight: 700;
}


/* Not logged in cart form ------------------------- */
.cart-customer-info {
    padding: 12px 16px;
    margin: 40px 0;
    background: var(--grey);
}


/* Cart notes ------------------------- */
.cart-note-block {
    margin-bottom: 32px;
    border: 2px dashed var(--secondary);
    font-size: var(--font-size-xs);
    line-height: 1.45em;
}
.cart-note-section {
    padding: 12px 16px;
}
.cart-lead-time span {
    display: block;
    font-weight: 400;
}
.cart-note {
    background: var(--grey);
}


/* Cart totals ------------------------- */
.cart-totals {
    border: 2px solid var(--black);
}
.cart-totals-row {
    padding: 8px 16px;
    border-bottom: 1px solid var(--charcoal);
}
.cart-totals-row:last-child {
    border: none;
    background: var(--primary);
    font-size: var(--font-size-xl);
    font-weight: 700;
    color: var(--white);
}
.total-column {
   display: inline-block;
   width: 50%;
}
.total-column:nth-child(2) {
    text-align: right;
}



/* Account address section ------------------------- */
/* -------------------------------------------------- */
.address-block {
    margin-bottom: 12px;
}
.account-address {
    padding: 16px 24px 12px;
    border-top: 1px solid var(--charcoal);
    background: var(--lightgrey);
}
.address-button {
    padding: 6px;
    border: 8px solid var(--lightgrey);
    background: var(--grey);
    color: var(--secondary);
    font-size: var(--font-size-s);
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.address-button:hover {
    background: var(--secondary);
    color: var(--white);
}




/* Mystery Meat ------------------------- */
/* -------------------------------------------------- */
.hot-point {
    background: url("../img/group_img/hot-point.webp") no-repeat;
    height: 36px;
    width: 36px;
}
.product-part-item{
    display:none;
}
.small-input input::-webkit-outer-spin-button, .small-input input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    background: #eee url("../img/group_img/arrows-number-input-small.webp") no-repeat 50% 50%;
    background-color: white;
    padding-top: 10px;
}
.group-attachments {
    padding: 25px;
    text-align: center;
    border-style: dashed;
    border-color: var(--tamarillo);
    margin-bottom:20px;
}


@media screen and (min-width: 540px) {

    /* Cart notes and totals ------------------------- */
    /* -------------------------------------------------- */
    .cart-totals-columns {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
    }
    .cart-totals-column {
        width: 48%;
    }
    .cart-note-section {
        padding: 24px 32px;
    }

    .cart-customer-info {
        padding: 24px 32px;
    }

}


@media screen and (min-width: 640px) {

    .cart-item-details {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    .cart-item-column {
        width: 50%;
    }
    .cart-item-column.cart-item-info {
        background: var(--white);
    }
    .cart-item-column.cart-item-totals {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        padding-top: 8px;
        background: none;
        border-left: 1px solid var(--charcoal);
    }
    .cart-item-row.cart-item-total span.form-field input {
        background: var(--light);
    }
    .cart-item-row.cart-item-total {
        width: 100%;
    }
    .cart-item-row.cart-item-total:last-child {
        margin-top: auto;
        background: var(--grey);
        color: var(--charcoal);
    }

}