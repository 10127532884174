/* Header ------------------------- */
/* -------------------------------------------------- */
.header-container {
    position: relative;
    display: block;
}
header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: block;
    width: 100%;
    height: auto;
    padding: 16px 0;
    background: var(--grey);
}
.header-spacer {
    position: relative;
    display: block;
    width: 100%;
    height: 84px;
}


/* Header Logo ------------------------- */
/* -------------------------------------------------- */
.header-logo {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    display: block;
    margin: -28px 0 0 -15px;
    width: 230px;
    height: auto;
}
.header-logo a,
.header-logo a svg {
    position: relative;
    display: block;
    width: 100%;
    height: 58.84px;
    padding: 0;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.header-logo a svg .logo-1,
.header-logo a svg .logo-2,
.header-logo a svg .logo-3,
.header-logo a svg .logo-4,
.header-logo a svg .logo-5,
.header-logo a svg .logo-6,
.header-logo a svg .logo-7 {
    transform: translateX(0);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.header-logo a:hover svg .logo-1 {
    transform: translateX(-15px);
}
.header-logo a:hover svg .logo-2 {
    transform: translateX(-10px);
}
.header-logo a:hover svg .logo-3 {
    transform: translateX(-5px);
}
.header-logo a:hover svg .logo-4 {
    transform: translateX(0);
}
.header-logo a:hover svg .logo-5 {
    transform: translateX(5px);
}
.header-logo a:hover svg .logo-6 {
    transform: translateX(10px);
}
.header-logo a:hover svg .logo-7 {
    transform: translateX(15px);
}


/* Header Navigation ------------------------- */
/* -------------------------------------------------- */
.nav-menus {
    position: relative;
    z-index: 1;
    display: none;
    text-align: right;
    margin-bottom: 8px;
}
.nav-menu {
    position: relative;
    display: block;
    margin-bottom: 8px;
}
.nav-menu a {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-family: var(--font-family-open_sans);
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    color: var(--charcoal);
}
.nav-menu a:hover {
    color: var(--secondary);
}
.primary-menu a {
    font-size: 30px;
}
.secondary-menu {
    font-size: 24px;
}
.nav-menu a.button {
    margin-left: 16px;
    padding-top: 8px;
    background: var(--secondary);
    font-family: var(--title-font);
    font-weight: 400;
    color: var(--white);
}
.nav-menu a.button.phone-link:hover {
    background: var(--primary);
}


/* Nav Cart ------------------------- */
/* -------------------------------------------------- */
.nav-cart-display {
    height: 39px;
}
.nav-cart {
    position: relative;
    width: 25px;
    height: 22px;
}
.nav-cart img {
    position: absolute;
}
.nav-cart img.basket {
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 5px);
}
.nav-cart img.front-wheel,
.nav-cart img.back-wheel {
    bottom: 0;
    width: 4px;
    height: 4px;
}
.nav-cart img.front-wheel {
    left: calc(44.75% - 2px);
}
.nav-cart img.back-wheel {
    right: 3px;
}


/* Mobile Nav Buttons ------------------------- */
/* -------------------------------------------------- */
.responsive-nav-buttons {
    position: relative;
    z-index: 1;
    display: block;
    text-align: right;
}
.responsive-nav-phone {
    position: relative;
    display: none !important;
    vertical-align: middle;
    padding: 8px 16px 12px;
    background: var(--secondary);
    font-family: var(--title-font);
    font-size: 20px;
    font-style: normal;
    text-transform: uppercase;
    line-height: 1em;
    color: var(--white);
}
a.responsive-nav-phone:hover {
    background: var(--primary);
    color: var(--white);
}
.hamburger {
    position: relative;
    display: inline-block !important;
    vertical-align: middle;
    width: 52px;
    height: 52px;
    margin: 0 0 0 12px;
    cursor: pointer;
}
.hamburger div {
    position: absolute;
    top: 50%;
    display: block;
    width: 52px;
    height: 11px;
    background: var(--charcoal);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.hamburger div.hamburger-top {
    margin-top: -20px;
    border-radius: 6px 6px 0 0;
}
.hamburger div.hamburger-middle {
    margin-top: -5.5px;
}
.hamburger div.hamburger-bottom {
    margin-top: 10px;
    border-radius: 0 0 6px 6px;
}
.hamburger:hover div {
    background: rgb(238,52,44);
}
.hamburger:hover div.hamburger-top {
    transform: translateY(-4px);
}
.hamburger:hover div.hamburger-bottom {
    transform: translateY(4px);
}


/* Mobile Nav Menus ------------------------- */
/* -------------------------------------------------- */
.responsive-nav {
    position: relative;
    top: 16px;
    display: none;
    width: 100%;
}
.responsive-menu {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    transform: translateX(0);
}
.responsive-nav.active .responsive-menu.responsive-primary-menu {
    transform: translateX(51vw);
}
.responsive-nav.active .responsive-menu.responsive-secondary-menu {
    transform: translateX(-51vw);
}
.responsive-menu a {
    position: relative;
    display: block;
    width: 100%;
    padding: 16px 5%;
    border-bottom: 2px solid #ccc;
    background: #eaeaea;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    color: #222;
}
.responsive-menu.responsive-secondary-menu a {
    background: #ddd;
}
.responsive-menu.responsive-primary-menu a:first-child {
    border-top: 2px solid #aaa;
}
.responsive-menu a:hover,
.responsive-menu.responsive-secondary-menu a:hover {
    background: #ccc;
}





@media (min-width: 360px) {

    /* Mobile Nav Menus ------------------------- */
    /* -------------------------------------------------- */
    .responsive-menu {
        position: absolute;
        top: 0;
        width: 50%;
    }
    .responsive-menu.responsive-secondary-menu a {
        background: #eaeaea;
    }
    .responsive-menu.responsive-primary-menu {
        right: 0;
        text-align: right;
    }
    .responsive-menu.responsive-secondary-menu {
        left: 0;
        text-align: left;
    }
    .responsive-menu.responsive-primary-menu a {
        border-left: 1px solid #ccc;
    }
    .responsive-menu.responsive-secondary-menu a {
        border-right: 1px solid #ccc;
    }
    .responsive-menu.responsive-secondary-menu a:first-child {
        border-top: 2px solid #aaa;
    }

}



@media (min-width: 540px) {

    /* Mobile Nav Buttons ------------------------- */
    /* -------------------------------------------------- */
    .responsive-nav-phone {
        display: inline-block !important;
    }
    .responsive-menu a {
        font-size: 22px;
    }
    .responsive-menu a.responsive-primary-nav-phone {
        display: none;
    }
    
}

@media (min-width: 768px) {

    .header-spacer {
        height: 96px;
    }

    /* Header Logo ------------------------- */
    /* -------------------------------------------------- */
    .header-logo {
        margin-top: -34px;
        width: 270px;
    }
    .header-logo a,
    .header-logo a svg {
        height: 70.61px;
    }

    /* Mobile Nav Buttons ------------------------- */
    /* -------------------------------------------------- */
    .responsive-nav-phone {
        font-size: 25px;
    }
    .hamburger {
        width: 64px;
        height: 64px;
        margin: 0 0 0 12px;
    }
    .hamburger div {
        width: 64px;
        height: 13px;
    }
    .hamburger div.hamburger-top {
        margin-top: -24px;
        border-radius: 6px 6px 0 0;
    }
    .hamburger div.hamburger-middle {
        margin-top: -6.5px;
    }
    .hamburger div.hamburger-bottom {
        margin-top: 12px;
        border-radius: 0 0 6px 6px;
    }
    .hamburger:hover div {
        background: var(--primary);
    }

}



@media (min-width: 1180px) {

    /* Header ------------------------- */
    /* -------------------------------------------------- */
    .header-spacer {
        height: 134px;
    }
    .header-logo {
        margin-top: -39px;
        width: 310px;
    }
    .header-logo a,
    .header-logo a svg {
        height: 82.39px;
    }

    /* Mobile Nav ------------------------- */
    /* -------------------------------------------------- */
    .responsive-nav-buttons {
        display: none;
    }


    /* Header Navigation ------------------------- */
    /* -------------------------------------------------- */
    .nav-menus {
        display: block;
    }

}