/* Footer ------------------------- */
/* -------------------------------------------------- */
footer {
    padding-top: 50px;
    padding-bottom: 16px;
}
footer p {
    color: var(--grey);
}
footer a {
    color: var(--grey);
}
footer a:hover {
    color: var(--white);
}
.footer-company-info {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--darkgrey);
    text-align: center;
}


/* Footer Logo ------------------------- */
/* -------------------------------------------------- */
.footer-logo {
    display: block;
}
a.footer-logo,
a.footer-logo svg.logo-svg {
    width: 282px;
    height: 74.14px;
    margin: 0 auto;
}
a.footer-logo .logo-1,
a.footer-logo .logo-2,
a.footer-logo .logo-3,
a.footer-logo .logo-4,
a.footer-logo .logo-5,
a.footer-logo .logo-6,
a.footer-logo .logo-7 {
    transform: translateX(0);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
a.footer-logo:hover .logo-1 {
    transform: translateX(-15px);
}
a.footer-logo:hover .logo-2 {
    transform: translateX(-10px);
}
a.footer-logo:hover .logo-3 {
    transform: translateX(-5px);
}
a.footer-logo:hover .logo-4 {
    transform: translateX(0);
}
a.footer-logo:hover .logo-5 {
    transform: translateX(5px);
}
a.footer-logo:hover .logo-6 {
    transform: translateX(10px);
}
a.footer-logo:hover .logo-7 {
    transform: translateX(15px);
}


/* Footer Contact ------------------------- */
/* -------------------------------------------------- */
.footer-contact {
    margin: 24px 0;
    text-transform: uppercase;
}
.footer-contact a.footer-phone {
    display: block;
    margin-bottom: 12px;
    font-size: var(--font-size-l);
    font-style: italic;
    font-weight: 600;
    color: var(--white);
}
.footer-contact p {
    font-weight: 300;
    line-height: 1.25em;
}


/* Footer Social ------------------------- */
/* -------------------------------------------------- */
.footer-social {
    display: block;
    margin: 8px 0 0;
}
.footer-social ul {
    margin: 0;
    padding: 0;
}
.footer-social ul li {
    display: inline-block;
}
.footer-social a {
    display: inline-block;
    margin: 0 8px;
}
.footer-social a svg {
    fill: var(--grey);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.footer-social a:hover svg {
    fill: var(--white);
}


/* Footer Bottom Row ------------------------- */
/* -------------------------------------------------- */
.footer-legal {
    margin-top: 24px;
}


/* Accreditations ------------------------- */
/* -------------------------------------------------- */
.footer-accreditations {
    text-align: center;
}
.footer-accreditations img {
    position: relative;
    display: block;
    width: 240px;
    height: auto;
    margin: 0 auto 32px;
}


/* Thunderstruck ------------------------- */
/* -------------------------------------------------- */
.footer-website-by a {
    text-align: center;
}
.footer-website-by a svg.tsm-badge {
    display: block;
    width: 49px;
    height: 40.25px;
    margin: 0 auto 8px;
    fill: var(--darkgrey);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.footer-website-by a:hover svg.tsm-badge {
    fill: var(--white);
}
.footer-website-by a .tsm-message {
    display: block;
    font-size: var(--font-size-s);
    font-weight: 300;
    line-height: 1.4em;
}


/* Copyrights ------------------------- */
/* -------------------------------------------------- */
.footer-copyrights {
    margin-top: 50px;
    font-size: var(--font-size-s);
}



@media screen and (min-width: 500px) {

    .footer-legal {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .footer-website-by a .tsm-message {
        display: inline-block;
        vertical-align: middle;
    }

}


@media screen and (min-width: 768px) {

    .footer-accreditations img {
        width: 280px;
    }
    .footer-website-by a {
        text-align: left;
    }
    .footer-website-by a svg.tsm-badge {
        display: inline-block;
        vertical-align: middle;
        margin: 0 12px 0 0;
    }

}


@media screen and (min-width: 900px) {
    /* Footer company info ------------------------- */
    /* -------------------------------------------------- */
    .footer-company-info {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .footer-logo {
        order: 2;
    }
    .footer-contact {
        order: 3;
        margin-bottom: 0;
        text-align: right;
    }
    .footer-contact a.footer-phone {
        margin-bottom: 0;
    }
    .footer-social {
        order: 1;
    }

}