/* Address list - modal popup ------------------------- */
/* -------------------------------------------------- */
.address-listings {
    font-size: var(--font-size-s);
    line-height: 1.5em;
}
.address-listing {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid var(--grey);
}
.address-listing .item-address {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}
.address-listing ul {
    width: 100%;
    margin: 0;
    padding: 12px 18px;
}
.address-listing ul li {
    display: block;
}
.use-this-address {
    width: 100%;
    padding: 5px;
    margin-top: auto;
    background: var(--lightgrey);
    font-weight: 600;
    color: var(--secondary);
    text-align: center;
    cursor: pointer;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.use-this-address:hover {
    background: var(--primary);
    color: var(--white);
}



@media screen and (min-width: 500px) {

    .address-listings {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
    }

    .address-listing {
        width: 48%;
    }

}