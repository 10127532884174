/* Fonts - Get locally - Open Sans ------------------------- */
/* -------------------------------------------------- */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/open-sans/open-sans-v29-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/open-sans/open-sans-v29-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans/open-sans-v29-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans/open-sans-v29-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans/open-sans-v29-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans/open-sans-v29-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/open-sans/open-sans-v29-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/open-sans/open-sans-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans/open-sans-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans/open-sans-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans/open-sans-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans/open-sans-v29-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/open-sans/open-sans-v29-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/open-sans/open-sans-v29-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans/open-sans-v29-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans/open-sans-v29-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans/open-sans-v29-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans/open-sans-v29-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/open-sans/open-sans-v29-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/open-sans/open-sans-v29-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans/open-sans-v29-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans/open-sans-v29-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans/open-sans-v29-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans/open-sans-v29-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/open-sans/open-sans-v29-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/open-sans/open-sans-v29-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans/open-sans-v29-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans/open-sans-v29-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans/open-sans-v29-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans/open-sans-v29-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/open-sans/open-sans-v29-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/open-sans/open-sans-v29-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans/open-sans-v29-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans/open-sans-v29-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans/open-sans-v29-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans/open-sans-v29-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}


/* Fonts - Get locally - Zilla Slab ------------------------- */
/* -------------------------------------------------- */
@font-face {
    font-family: 'Zilla Slab';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/zilla-slab/zilla-slab-v11-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/zilla-slab/zilla-slab-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/zilla-slab/zilla-slab-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/zilla-slab/zilla-slab-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/zilla-slab/zilla-slab-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/zilla-slab/zilla-slab-v11-latin-regular.svg#ZillaSlab') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Zilla Slab';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/zilla-slab/zilla-slab-v11-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/zilla-slab/zilla-slab-v11-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/zilla-slab/zilla-slab-v11-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/zilla-slab/zilla-slab-v11-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/zilla-slab/zilla-slab-v11-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/zilla-slab/zilla-slab-v11-latin-600.svg#ZillaSlab') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Zilla Slab';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/zilla-slab/zilla-slab-v11-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/zilla-slab/zilla-slab-v11-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/zilla-slab/zilla-slab-v11-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/zilla-slab/zilla-slab-v11-latin-600italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/zilla-slab/zilla-slab-v11-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/zilla-slab/zilla-slab-v11-latin-600italic.svg#ZillaSlab') format('svg'); /* Legacy iOS */
}


/* Variables ------------------------- */
/* -------------------------------------------------- */
:root {
    --white: #ffffff;
    --lightgrey: #f2f2f2;
    --grey: #dddddd;
    --darkgrey: #606060;
    --charcoal: #222222;
    --black: #1A1A1A;
    --primary: #EE342C;
    --secondary: #980E1B;
    --success: #2C532F;
    --font-size-l: 1em;
    --font-size-l: 1.25em;
    --font-size-s: 0.85em;
    --font-size-xl: 1.5em;
    --font-size-xs: 0.7em;
    --body-font: "Open Sans", Helvetica, sans-serif;
    --title-font: "Zilla Slab", Helvetica, serif;
}
div#root {overflow: initial !important;}


/* Global Styles ------------------------- */
/* -------------------------------------------------- */
* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}
*::selection {
    background-color: var(--primary);
    color: var(--white);
}
.hidden,
.hidden * {
    visibility: hidden;
    pointer-events: none;
}
html {
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    height: 100%;
}
div#root {
    overflow: hidden;
}
body {
    margin: 0;
    padding: 0;
    height: 100%;
    background: var(--charcoal);
    font-family: var(--body-font);
    font-size: 14px;
    line-height: 1.6em;
    font-weight: 400;
    color: var(--black);
}
main {
    background: var(--grey);
}
section, div {
    position: relative;
    display: block;
    -webkit-text-size-adjust: none;
}

.display-none {display: none;}


/* Wrappers ---------------------------------------------- */
/* -------------------------------------------------------------------------------------------- */
.wrapper,
.inner-wrapper {
    position: relative;
    display: block;
    max-width: 1600px;
    margin: 0 auto;
}
.wrapper {
    width: 90%;
}
.inner-wrapper {
    width: 100%;
}
.wrapper-overflow {
    overflow: auto;
}
.xlarge-wrapper {
    max-width: 1920px;
}
.large-wrapper {
    max-width: 1640px;
}
.medium-wrapper {
    max-width: 1200px;
}
.thin-wrapper {
    max-width: 980px;
}
.xthin-wrapper {
    max-width: 750px;
}
.wrapper-full {
    width: 100%;
}
.mobile-full-wrapper {
    width: 100%;
}


/* Backgrounds ---------------------------------------------- */
/* -------------------------------------------------------------------------------------------- */
.bg-white {
    background: var(--white);
}
.bg-light {
    background: var(--lightgrey);
}
.bg-grey {
    background: var(--grey);
}
.bg-darkgrey {
    background: var(--darkgrey);
}
.bg-charcoal {
    background: var(--charcoal);
    color: var(--white);
}
.bg-black {
    background: var(--black);
    color: var(--white);
}
.bg-primary {
    background: var(--primary);
    color: var(--white);
}
.bg-secondary {
    background: var(--secondary);
    color: var(--white);
}
section.bg-white + section.bg-white,
section.bg-grey + section.bg-grey,
section.bg-charcoal + section.bg-charcoal,
section.bg-black + section.bg-black,
section.bg-primary + section.bg-primary,
section.bg-secondary + section.bg-secondary {
    padding-top: 0;
}

.filter {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
}
.filter-9 {
    opacity: 0.9;
}
.filter-8 {
    opacity: 0.8;
}
.filter-7 {
    opacity: 0.7;
}
.filter-6 {
    opacity: 0.6;
}
.filter-5 {
    opacity: 0.5;
}
.filter-4 {
    opacity: 0.4;
}
.filter-3 {
    opacity: 0.3;
}
.filter-2 {
    opacity: 0.2;
}
.filter-1 {
    opacity: 0.1;
}


/* Spacing ---------------------------------------------- */
/* -------------------------------------------------------------------------------------------- */
.xs-padding {
    padding-top: 15px;
    padding-bottom: 15px;
}
.xs-padding-top {
    padding-top: 15px;
}
.xs-padding-bottom {
    padding-bottom: 15px;
}
.small-padding {
    padding-top: 25px;
    padding-bottom: 25px;
}
.small-padding-top {
    padding-top: 25px;
}
.small-padding-bottom {
    padding-bottom: 25px;
}
.medium-padding {
    padding-top: 35px;
    padding-bottom: 35px;
}
.medium-padding-top {
    padding-top: 35px;
}
.medium-padding-bottom {
    padding-bottom: 35px;
}
.large-padding {
    padding-top: 45px;
    padding-bottom: 45px;
}
.large-padding-top {
    padding-top: 45px;
}
.large-padding-bottom {
    padding-bottom: 45px;
}
.xl-padding {
    padding-top: 60px;
    padding-bottom: 60px;
}
.xl-padding-top {
    padding-top: 60px;
}
.xl-padding-bottom {
    padding-bottom: 60px;
}
.side-padding {
    padding-left: 5%;
    padding-right: 5%;
}
.no-margin {
    margin: 0;
}
.no-margin-bottom {
    margin-bottom: 0;
}
.no-margin-top {
    margin-top: 0;
}


/* alignment ---------------------------------------------- */
/* -------------------------------------------------------------------------------------------- */
.center-align {
    text-align: center;
}
.right-align {
    text-align: right;
}


/* text styles ---------------------------------------------- */
/* -------------------------------------------------------------------------------------------- */
.uppercase {
    text-transform: uppercase;
}
.lowercase {
    text-transform: initial;
}
p {
    margin: 0 0 18px;
}
p:last-child {
    margin-bottom: 0;
}
.text-lg {
    font-size: var(--font-size-l);
    line-height: 1.55em;
}
.text-xl {
    font-size: var(--font-size-xl);
    line-height: 1.55em;
}
.text-sm {
    font-size: var(--font-size-s);
    line-height: 1.55em;
}
.text-xs {
    font-size: var(--font-size-xs);
    line-height: 1.55em;
}
.text-white {
    color: var(--white);
}
.text-grey {
    color: var(--grey);
}
.text-charcoal {
    color: var(--charcoal);
}
.text-black {
    color: var(--black);
}
.text-red {
    color: var(--primary);
}
.highlight {
    display: inline;
    color: var(--white);
    border-bottom: 0 solid transparent;
    background-image: linear-gradient(var(--secondary),var(--secondary));
    background-size: 100% 1.1em;
    background-repeat: no-repeat;
    background-position: left 60%;
    line-height: 1.175em;
}
.highlight.highlight-black {
    background-image: linear-gradient(var(--black),var(--black));
}
.highlight.highlight-white {
    background-image: linear-gradient(var(--white),var(--white));
    color: var(--charcoal);
}
.highlight.highlight-primary {
    background-image: linear-gradient(var(--primary),var(--primary));
}
.highlight.highlight-secondary {
    background-image: linear-gradient(var(--secondary),var(--secondary));
}


/* Headings ---------------------------------------------- */
/* -------------------------------------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6 {
    position: relative;
    display: block;
    font-weight: 400;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
h1, h2, h3 {
    font-family: var(--title-font);
}
main h1, h2 {
    font-style: italic;
    font-size: 3em;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 48px;
}
h3 {
    font-size: 2em;
    line-height: 1.15em;
}
h4 {
    font-weight: 700;
    font-size: 1.25em;
    line-height: 1.25em;
}
h5 {
    font-size: 1.05em;
    line-height: 1.45em;
}
h6 {
    font-size: 1.05em;
    line-height: 1.45em;
}
.subheading {
    font-style: initial;
    font-family: var(--body-font);
    font-weight: 700;
    text-transform: initial;
}
h1, h2 {
    margin-bottom: 24px;
}
h3 {
    margin-bottom: 18px;
}
h4 {
    margin-bottom: 12px;
}
h5, h6 {
    margin-bottom: 0;
}
h1, h2, h3 {
    margin-top: 36px;
}
h4, h5, h6 {
    margin-top: 24px;
}
h1 + h2, h1 + h3, h1 + h4, h1 + h5, h1 + h6,
h2 + h3, h2 + h4, h2 + h5, h2 + h6,
h3 + h2, h3 + h4, h3 + h5, h3 + h6,
h4 + h2, h2 + h3, h4 + h5, h4 + h6,
h5 + h2, h5 + h3, h5 + h4, h5 + h6,
h6 + h2, h6 + h3, h6 + h4, h6 + h5 {
    margin-top: 0;
}
h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
    margin-top: 0;
}
h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child {
    margin-bottom: 0;
}
h2 + ul, h3 + ul, h4 + ul,
h2 + ol, h3 + ol, h4 + ol {
    margin-top: 0 !important;
}



/* --------------------------------------------------------------------------------------- */
/* Lists ---------------------------------------------- */
/* --------------------------------------------------------------------------------------- */
/* Shared styles ---------------------------------------------- */
ul, ol {
    margin: 0;
    padding: 0;
}
ol {
    counter-reset: item;
    list-style: none;
}
.long-text ul, ol {
    margin: 24px 0;
}
.long-text ul:first-child, ol:first-child {
    margin-top: 0;
}
.long-text ul:last-child, ol:last-child {
    margin-top: 0;
}
.long-text ul li, ol li {
  display: block;
  margin-left: 40px;
  margin-bottom: 8px;
}
.long-text ul li:last-child, 
ol li:last-child {
    margin-bottom: 0;

}
.long-text ul li:before, 
ol li:before {
  position: relative;
  float: left;
  margin-left: -25px;
  color: var(--primary);
}

/* UL styles ---------------------------------------------- */
.long-text ul li {
    list-style: none;
}
.long-text ul li:before {
    content: '\2022';
}
.long-text ul ul {
    margin-top: 5px;
}

/* OL styles ---------------------------------------------- */
ol li {
    counter-increment: item;
}
ol li:before {
  content: counters(item, '.') ' ';
  font-size: var(--font-size-s); 
  font-weight: 500;
}
.long-text ol ol {
    margin-top: 5px;
}
.long-text ol ol li:before {
    margin-left: -40px;
}



/* Links ------------------------- */
/* -------------------------------------------------- */
a {
    position: relative;
    text-decoration: none !important;
    cursor: pointer;
    color: var(--primary);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
a:hover {
    color: var(--secondary);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.phone-link {pointer-events: initial;}


/* Buttons ------------------------- */
/* -------------------------------------------------- */
.buttons-wrapper {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
}
.button {
    overflow: hidden;
    display: inline-block;
    padding: 12px 24px;
    margin-left: 12px;
    background: var(--secondary);
    font-weight: 700;
    color: var(--white);
    line-height: 1em;
    cursor: pointer;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.button:hover {
    background: var(--primary);
    color: var(--white);
}
.even-buttons .button {
    width: 200px;
}
.button.outline-button {
    background: none !important;
    border: 1px solid var(--secondary);
    font-size: var(--font-size-m);
    font-weight: 400;
    color: var(--secondary);
}
.button.outline-button:hover {
    background: var(--secondary) !important;
    color: var(--white);
}
.big-buttons .button {
    padding: 16px 40px;
    font-weight: 600;
    font-size: var(--font-size-l);
}
.title-buttons .button {
    padding: 5px 4px 6px;
    font-family: var(--title-font);
    font-size: var(--font-size-xl);
}

.bg-primary .button,
.bg-secondary .button {
    background: var(--grey);
    color: var(--black);
}

.button.button-bg-white {
    background: var(--white);
}
.button.button-bg-grey {
    background: var(--grey);
}
.button.button-bg-charcoal {
    background: var(--charcoal);
}
.button.button-bg-black {
    background: var(--black);
}
.button.button-bg-primary {
    background: var(--primary);
}
.button.button-bg-secondary {
    background: var(--secondary);
}
.button.button-bg-white:hover {
    background: var(--grey);
}
.button.button-bg-grey:hover {
    background: var(--white);
}
.button.button-bg-charcoal:hover {
    background: var(--secondary);
}
.button.button-bg-black:hover {
    background: var(--secondary);
}
.button.button-bg-primary:hover {
    background: var(--secondary);
}
.button.button-bg-secondary:hover {
    background: var(--primary);
}
.button.button-text-white {
    color: var(--white);
}
.button.button-text-grey {
    color: var(--grey);
}
.button.button-text-charcoal {
    color: var(--charcoal);
}
.button.button-text-black {
    color: var(--black);
}
.button.button-text-primary {
    color: var(--primary);
}
.button.button-text-secondary {
    color: var(--secondary);
}
.buttons-wrapper.center-buttons {
    text-align: center;
}
.buttons-wrapper.right-buttons {
    text-align: right;
}
.buttons-wrapper .button {
    margin: 3px 12px 3px 0;
}
.buttons-wrapper.center-buttons .button {
    margin: 3px 6px;
}
.buttons-wrapper.right-buttons .button {
    margin: 3px 0 3px 12px;
}


/* Logo ------------------------- */
/* -------------------------------------------------- */
svg.logo-svg .logo-white {
    fill: var(--white);
}
svg.logo-svg .logo-black {
    fill: var(--black);
}
svg.logo-svg .logo-primary {
    fill: var(--primary);
}
svg.logo-svg .logo-iron-and-steel {
    position: relative;
}
svg.logo-svg .logo-iron-and-steel-outline {
    z-index: 2;
}
svg.logo-svg .logo-iron-and-steel-fill {
    z-index: 3;
}


/* Section - hero and banners ------------------------- */
/* -------------------------------------------------- */
.hero {
    width: 100%;
    height: 60vw;
    max-height: 560px;
}


/* Section - title bar ------------------------- */
/* -------------------------------------------------- */
.page-title {
    overflow: hidden;
    padding: 16px 0;
}
.page-title h1 {
    width: 64%;
    margin: 0;
    color: var(--white);
    font-weight: 500;
    font-size: 2em;
}
.page-title-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 65%;
    max-width: calc(50% + 300px);
}
.page-title-bar img {
    position: absolute;
    left: 99%;
    top: 0;
    width: auto;
    height: 100%;
}


/* Breadcrumbs ------------------------- */
/* -------------------------------------------------- */
ul.breadcrumb {
    padding: 6px 0 0;
    margin: 0;
    list-style: none;
    background: none;
}
ul.breadcrumb li {
    display: inline;
    font-size: var(--font-size-xs);
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    color: var(--darkgrey);
}
ul.breadcrumb li:last-child {color: var(--primary);}
ul.breadcrumb li + li:before {
    content: ">";
    display: inline-block;
    margin: 0 8px;
    font-weight: 700;
    color: var(--darkgrey);
}
ul.breadcrumb li a {
    color: var(--grey);
    text-decoration: none;
}
ul.breadcrumb li a:hover {
    color: var(--primary) !important;
}


/* Section - half columns ------------------------- */
/* -------------------------------------------------- */
.half-column.column-image-bg {
    height: 60vw;
}


/* modal popup ------------------------- */
/* -------------------------------------------------- */
.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 101;
}
.ReactModal__Content {
    padding: 0 !important;
    width: 90% !important;
    max-width: 600px;
    height: initial !important;
}
.popup-header-bar {
    background: var(--grey);
}
.popup-header-bar .popup-title {
    width: calc(100% - 50px);
    padding: 16px 0;
    margin: 0;
}
.popup-content {
    padding: 24px 0;
}


/* Close buttons ------------------------- */
/* -------------------------------------------------- */
.close-button {
    position: absolute;
    right: 0;
    top: 50%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    border-radius: 4px;
    background: var(--grey);
    cursor: pointer;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.close-button:hover {
    background: var(--primary);
}
.close-button span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    margin-left: -10px;
    height: 4px;
    margin-top: -2px;
    background: var(--charcoal);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.close-button:hover span {
    background: var(--white);
}
.close-button span:first-child {
    transform: rotate(45deg);
}
.close-button:hover span:first-child {
    transform: rotate(135deg);
}
.close-button span:last-child {
    transform: rotate(-45deg);
}
.close-button:hover span:last-child {
    transform: rotate(45deg);
}


/* Mystery Meat ------------------------- */
/* -------------------------------------------------- */
.table-bordered {
    border: unset;
}
.viewOrder {
    color: white;
}
input#searchOrder {
    margin-bottom: 50px;
}
.see-more {
    background-color: transparent;
    color: #AFAFAF;
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-26_4px);
    font-style: normal;
    font-weight: 400;
    height: 55px;
    letter-spacing: 0.00px;
    line-height: 77.9px;
    position: relative;
    text-align: center;
    border-bottom: 1px solid #AFAFAF;
    margin: auto;
    cursor: pointer;
    display: unset !important;
}
.notification {
    text-decoration: none;
    display: inline-block;
    border-radius: 2px;
    top: -65px;
}
.notification:hover {
    background: red;
}
    .notification .badge {
        position: absolute;
        top: 9px;
        right: -15px;
        padding: 5px;
        border-radius: 50%;
        background-color: var(--tamarillo);
        color: white;
        font-size: small;
        min-width: 23px;
        text-align: center;
        background: brown;
    }
.empty .group-39-xQz8rX {
    position: fixed;
}
.img-mobile-cart {
    margin-right: 10px;
}
.empty .group-39-lNsoBS {
    position: fixed;
}
.empty .group-90-xRzIwx {
    position: fixed;
}
.invalid {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-repeat: no-repeat !important;
    background-position: right calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}
#togglePassword {
    margin-left: -30px;
    cursor: pointer;
    z-index: 1;
    margin-top: 12px;
}
#toggleRePassword {
    margin-left: -30px;
    cursor: pointer;
    z-index: 1;
    margin-top: 12px;
}
#toggleOldPassword {
    margin-left: -30px;
    cursor: pointer;
    z-index: 1;
    margin-top: 12px;
}



@media screen and (min-width: 560px) {

    body {
        font-size: 16px;
    }

}



@media screen and (min-width: 640px) {

    /* Padding ---------------------------------------------- */
    .xsm-padding {
        padding-top: 2vw;
        padding-bottom: 2vw;
    }
    .xsm-padding-top {
        padding-top: 2vw;
    }
    .xsm-padding-bottom {
        padding-bottom: 2vw;
    }
    .small-padding {
        padding-top: 4vw;
        padding-bottom: 4vw;
    }
    .small-padding-top {
        padding-top: 4vw;
    }
    .small-padding-bottom {
        padding-bottom: 4vw;
    }
    .medium-padding {
        padding-top: 6vw;
        padding-bottom: 6vw;
    }
    .medium-padding-top {
        padding-top: 6vw;
    }
    .medium-padding-bottom {
        padding-bottom: 6vw;
    }
    .large-padding {
        padding-top: 8vw;
        padding-bottom: 8vw;
    }
    .large-padding-top {
        padding-top: 8vw;
    }
    .large-padding-bottom {
        padding-bottom: 8vw;
    }
    .xl-padding {
        padding-top: 10vw;
        padding-bottom: 10vw;
    }
    .xl-padding-top {
        padding-top: 10vw;
    }
    .xl-padding-bottom {
        padding-bottom: 10vw;
    }
}


@media (min-width: 768px) {

    body {
        font-size: 18px;
        line-height: 1.7em;
    }
    .phone-link {
        pointer-events: none;
    }
    .page-title h1 {
        font-size: 3em;
    }

}


@media screen and (min-width: 1000px) {

    body {
        font-size: 19px;
    }

    .mobile-full-wrapper {
        width: 90%;
    }

    /* Section - half columns ------------------------- */
    /* -------------------------------------------------- */
    .half-columns {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    .half-column {
        width: 50%;
    }
    .half-column.column-left {
        order: 1;
    }
    .half-column.column-right {
        order: 2;
    }
    .half-column.column-image-bg {
        height: auto;
    }

}