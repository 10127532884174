
.label-oops-group {
    position: absolute;
    top: 25%;
    left: 10%;
    z-index: 1;
}

    .label-oops-group h2.small-padding-bottom {
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

span.label-oops-nothing {
    font-size: var(--font-size-xl);
    font-weight: 600;
    font-style: italic;
}

a.col-xs-7.col-sm-6.col-lg-3.button.button-text-white {
    padding: 15px;
}


@media screen and (min-width: 320px) {
    span.label-oops-nothing {
        font-size: larger;
        margin-bottom: 10px !important;
    }
    .label-oops-group h2.small-padding-bottom {
        font-size: var(--font-size-xl);
    }
    .label-oops-group h2.small-padding-bottom {
        padding-bottom: unset;
        margin-bottom: unset;
    }
    .label-oops-group h2.small-padding-bottom {
        font-size: 30px !important;
    }
    .label-oops-group {
        position: absolute;
        top: 18%;
    }
        .label-oops-group h2.small-padding-bottom {
            margin-bottom: 5px;
        }

    .buttons-wrapper.center-buttons {
        left: -5px;
    }
    }


@media screen and (min-width: 768px) {

    .label-oops-group h2.small-padding-bottom {
        font-size: 40px !important;
    }
}

@media screen and (min-width: 900px) {
    span.label-oops-nothing {
        font-size: var(--font-size-xl);
    }
    .label-oops-group h2.small-padding-bottom {
        font-size: var(--font-size-xl);
    }
    .label-oops-group h2.small-padding-bottom {
        font-size: 50px !important;
        margin-top: 20px;
    }
    .label-oops-group {
        position: absolute;
        top: 25%;
        left: 5%;
    }
        .label-oops-group h2.small-padding-bottom {
            margin-bottom: 10px;
        }
}

@media screen and (min-width:1281px) {
    span.label-oops-nothing {
        font-size: var(--font-size-xl);
    }
    .label-oops-group h2.small-padding-bottom {
        margin-bottom: 10px;
    }

    .label-oops-group h2.small-padding-bottom {
        font-size: 80px !important;
        margin-top: 20px !important;
    }
    .buttons-wrapper.center-buttons {
        margin-top: 25px;
    }
}
