/* General Styles ------------------------------------------------------ */
/* ---------------------------------------------------------------------- */
#root {
    background: #f2f2f2;
}
#print-page-container {
    position: relative;
    display: block;
    width: 8.5in;
    margin: 0 auto;
    background: #fff;
    font-size: 10pt;
}

/* Cover Page ------------------------------------------------------ */
/* ---------------------------------------------------------------------- */
#print-cover {
    position: relative;
    z-index: 99;
    display: block;
    width: 8.5in;
    height: 11in;
}

/* Cover Header ------------------------------------------------------ */
.print-cover-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    display: block;
    width: 100%;
    padding: 0.5in 0.625in 0;
    font-size: 16pt;
}
.print-cover-logos {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25in;
}
img.print-tsm-cover-logo {
    width: 60mm;
    height: auto;
}
img.print-jenkins-cover-logo {
    width: 40mm;
    height: auto;
}
.print-cover-header h1 {
    font-size: 1.75em;
    font-weight: 500;
}
.print-cover-header ul {
    margin: 0;
    padding: 0;
}
.print-cover-header ul li {
    display: inline-block;
    margin-right: 0.375in;
    font-weight: 500;
}


/* Cover Body ------------------------------------------------------ */
.print-cover-body {
    position: relative;
    z-index: 100;
    display: block;
    width: 100%;
    height: 100%;
}
.print-cover-body img {
    display: block;
    width: 100%;
    height: 100%;
}


/* Products Section - header ------------------------------------------------------ */
/* ---------------------------------------------------------------------- */
#print-product-pages {
    position: relative;
    display: block;
}
.print-header {
    position: relative;
    display: block;
    width: 8.5in;
    padding: 5mm 10mm;
    margin: 0 0 12mm;
    text-align: center;
}
.print-header-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.print-page-header-logo img {
    width: 44mm;
    height: auto;
}
.print-page-header-text {
    text-align: right;
}
.print-page-header-text h2 {
    position: relative;
    z-index: 2;
    margin: 0;
    font-size: 19pt;
    font-weight: 600;
}
.print-page-header-text p {
    position: relative;
    z-index: 2;
    margin: 0;
    font-size: 11pt;
}
.print-page-header-text img {
    position: absolute;
    top: 50%;
    right: 35px;
    z-index: 1;
    margin-top: -30px;
    width: 12mm;
    height: auto;
    opacity: 0.65;
}


/* Individual Products ------------------------------------------------------ */
/* ---------------------------------------------------------------------- */
.print-products {
    position: relative;
    display: block;
}
.print-product-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 0 10mm 20mm;
}

/* Individual Products - Left Column - Image */
.print-product-image {
    width: 2.5in;
    height: auto;
}
.print-product-image img {
    display: block;
    height: auto;
    width: 2.25in;
    margin: 0 auto;
}

/* Individual Products - Middle Column - Title and Features */
.print-product-content {
    width: 2.75in;
}
.print-product-content h3 {
    font-size: 14pt;
    font-weight: 700;
}
.print-product-content ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}
.print-product-content ul.features {
    word-wrap: break-word;
}
.print-product-content ul.features li {
    position: relative;
    display: block;
    padding-top: 1mm;
    margin-left: 6mm;
}
.print-product-content ul li:before {
    content: '\00BB';
    position: absolute;
    left: -5mm;
    transform: translateY(-1.8mm);
    color: #c10230;
}
.print-product-content ul.features li p {
    margin-bottom: 2px;
    line-height: 1.5em;
}

/* Individual Products - Right Column - Options */
.print-product-options {
    width: 1.625in;
}
.print-product-options table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 8pt;
}
.print-product-options table td, #customers {
    border: 1px solid #ddd;
    padding: 4px 6px;
    line-height: 1.15em;
}
.print-product-options table .prices-format {
    width: 14mm;
    color: #c10230;
    text-align: right;
}



/* Products Section - Footer ------------------------------------------------------ */
/* ---------------------------------------------------------------------- */
.print-footer {
    position: relative;
    display: block;
    width: 8.5in;
    padding: 5mm 10mm;
    text-align: center;
    font-size: 12pt;
}
.print-separator {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 600;
}
.print-separator::before, .print-separator::after {
    content: '';
    flex: 1;
    border-bottom: 2px solid #000;
}
.print-separator::before {
    margin-right: 12px;
}
.print-separator::after {
    margin-left: 12px;
}
.print-footer-info {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2mm;
}
.print-footer-info div {
    font-size: 9pt;
    font-weight: 600;
}
.print-footer .print-distribution img {
    display: inline-block;
    height: auto;
    width: 26mm;
    margin-left: 4px;
}
.print-footer .print-footer-graphic img {
    display: inline-block;
    height: auto;
    width: 21mm;
    margin-left: 4px;
}

    @media print {
        /* General Print Settings ------------------------------------------------------ */
        @page {
            size: letter;
            margin: 0mm;
        }
        #print-page-container {
            width: 100%;
        }

        /* Cover Page ------------------------------------------------------ */
        #print-cover {
            width: 100%;
            height: 100%;
        }
        .print-cover-header {
            font-size: 20pt;
        }
        img.print-tsm-cover-logo {
            width: 75mm;
        }
        img.print-jenkins-cover-logo {
            width: 50mm;
        }

        /* Header ------------------------------------------------------ */
        .print-header {
            position: fixed;
            top: 0;
            width: 100%;
        }

        /* All Products Container ------------------------------------------------------ */
        #print-product-pages {
            margin: 0mm 0mm 24mm;
        }
        .print-products {}

        /* Single Product ------------------------------------------------------ */
        .print-product-container {
            break-inside: avoid;
            padding-top: 28mm;
            margin-bottom: -28mm;
        }
        .print-product-container .product-column-print  {
            margin: 0;
        }
        .print-product-image {
            width: 3in;
        }
        .print-product-content {
            width: 3.25in;
        }
        .print-product-options {
            width: 1.75in;
        }
        .print-product-content .features .product-options {
            break-inside: avoid;
        }

        /* Footer ------------------------------------------------------ */
        .print-footer {
            position: fixed;
            bottom: 0;
            width: 100%;
        }

    }