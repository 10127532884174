/* Poduct List hero ---------------------------------------------- */
/* -------------------------------------------------------------------------------------------- */
.hero.product-list-hero {
    height: auto;
    max-height: initial;
    background: url(../img/group_img/product-list-hero-s.webp) no-repeat center center;
    background-size: cover;
}


/* Filter Bar ---------------------------------------------- */
/* -------------------------------------------------------------------------------------------- */
.product-filters {
    padding: 16px 0;
}
.product-category {
    display: inline-block;
    vertical-align: middle;
    margin: 3px 12px;
    font-size: var(--font-size-l);
    font-weight: 600;
    text-transform: uppercase;
}
.product-category span {
    display: block;
    padding: 4px;
    cursor: pointer;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.product-category span:hover {
    color: var(--secondary);
}
.product-category span:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: var(--secondary);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.product-category span.active-underline-group:after {
    width: 100%;
}


/* Poduct Listings container ---------------------------------------------- */
/* -------------------------------------------------------------------------------------------- */
.product-list-side-image {
    display: none;
}


/* Poduct preview blocks ---------------------------------------------- */
/* -------------------------------------------------------------------------------------------- */
.product-preview {
    display: inline-block;
    vertical-align: top;
    width: 46%;
    max-width: 400px;
    margin: 24px 2%;
    cursor: pointer;
}
/* .product-preview .product-preview-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--white);
    transform: scale(0.5);
    border-radius: 10px;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.product-preview:hover .product-preview-bg {
    transform: scale(0.65);
    background: var(--grey);
} */
.product-preview img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    transform: scale(0.95);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.product-preview:hover img {
    transform: scale(1.05);
}
.product-preview .product-preview-title {
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.product-preview:hover .product-preview-title {
    color: var(--secondary);
}



/* Poduct List sub footer ---------------------------------------------- */
/* -------------------------------------------------------------------------------------------- */
.product-list-footer {
    background: url(../img/group_img/product-list-hero-s.webp) no-repeat center center;
    background-size: cover;
}
.product-list-footer .wrapper {
    z-index: 2;
}



@media screen and (min-width: 640px) {

    .hero.product-list-hero {
        background: url(../img/group_img/product-list-hero-m.webp) no-repeat center center;
        background-size: cover;
    }
    .product-list-footer {
        background: url(../img/group_img/product-list-hero-m.webp) no-repeat center center;
        background-size: cover;
    }

    .product-preview {
        width: 29.3333%;
    }

}


@media screen and (min-width: 1080px) {

    .hero.product-list-hero {
        background: url(../img/group_img/product-list-hero-full.webp) no-repeat center center;
        background-size: cover;
    }
    .product-list-footer {
        background: url(../img/group_img/product-list-hero-full.webp) no-repeat center center;
        background-size: cover;
    }


    /* Poduct Listings container ---------------------------------------------- */
    /* -------------------------------------------------------------------------------------------- */
    .product-list-container {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: stretch;
    }
    .product-list-side-image {
        display: block;
        width: 30%;
    }
    .side-image-container {
        position: -webkit-sticky;
        position: sticky;
        top: 96px;
        width: 100%;
        height: calc(100vh - 96px);
        max-height: 100%;
        background: url(../img/group_img/products-list-side-image.webp) no-repeat center center;
        background-size: cover;
    }
    .product-listings {
        width: 65%;
    }

}


@media screen and (min-width: 1180px) {
    .side-image-container {
        top: 134px;
        height: calc(100vh - 134px);
    }
}

@media screen and (min-width: 1680px) {
    .product-preview {
        width: 21%;
    }
}